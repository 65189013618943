<script lang="ts">
    import { Inertia } from '@inertiajs/inertia';
    import { router, inertia } from '@inertiajs/svelte'
    import BmnLogo from '../Icons/bmnLogo.svelte';
    import { Button } from '$lib/components/ui/button';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';

    export let authUser;

    let open = false;

    function handleLogout() {
        Inertia.post('/logout');
    }
</script>

<nav class="py-3">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 justify-between">
            <div class="flex">
                <div class="flex flex-shrink-0 items-center">
                    <a href="/">
                        <BmnLogo />
                    </a>
                </div>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:items-center">
                {#if authUser}
                    <Button href="/posts/create" variant="ghost" class="">Create</Button>
                    <Button href="/feed" variant="ghost" class="">Feed</Button>
                {/if}
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:items-center">
                {#if !authUser}
                    <Button on:click={() => {router.visit('/login')}} class="text-white">Login</Button>
                    <Button on:click={() => {router.visit('/register')}}  variant="ghost" class="">Register</Button>
                {/if}
                {#if authUser}
                    <div class="relative ml-3">
                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger>
                                <img class="h-8 w-8 rounded-full" src="{authUser.avatar}" alt="{authUser.name}" />
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                                <DropdownMenu.Group>
                                    <DropdownMenu.Item href="/feed">Feed</DropdownMenu.Item>
                                    <DropdownMenu.Item href="/my/settings">Settings</DropdownMenu.Item>
                                    <DropdownMenu.Item href="/profile/{authUser.username}">Profile</DropdownMenu.Item>
                                    <DropdownMenu.Item href="/my/settings?type=subscriptions"
                                        >Subscriptions</DropdownMenu.Item
                                    >
                                    <DropdownMenu.Item href="/my/settings?type=payments">Payments</DropdownMenu.Item>
                                    <DropdownMenu.Separator />
                                    <DropdownMenu.Item on:click="{handleLogout}">Logout</DropdownMenu.Item>
                                </DropdownMenu.Group>
                            </DropdownMenu.Content>
                        </DropdownMenu.Root>
                    </div>
                {/if}
            </div>
            <div class="-mr-2 flex items-center sm:hidden">
                <Button
                    on:click="{() => (open = !open)}"
                    class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-6 w-6 text-white"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        ></path>
                    </svg>
                </Button>
            </div>
        </div>
    </div>
    {#if open}
        <div class="sm:hidden">
            <div class="space-y-1 pb-3 pt-2">
                {#if authUser}
                    <Button href="/posts/create" variant="ghost" class="">Create</Button>
                    <Button href="/feed" variant="ghost" class="">Feed</Button>
                {/if}
            </div>
            <div class="border-t border-gray-200 p-4 dark:border-gray-600">
                {#if !authUser}
                    <div class="mt-3 space-y-1">
                        <Button href="/login" class="text-white">Login</Button>
                        <Button href="/register" variant="ghost" class="">Register</Button>
                    </div>
                {/if}
                {#if authUser}
                    <div class="flex items-center px-4">
                        <div class="flex-shrink-0">
                            <img class="h-10 w-10 rounded-full" src="{authUser.avatar}" alt="{authUser.name}" />
                        </div>
                        <div class="ml-3">
                            <div class="text-base font-medium leading-none text-gray-800 dark:text-white">
                                {authUser.name}
                            </div>
                            <div class="mt-1 text-sm font-medium leading-none text-gray-500 dark:text-gray-400">
                                {authUser.email}
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 space-y-1">
                        <a
                            href="/feed"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Feed</a
                        >
                        <a
                            href="/my/settings"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Settings</a
                        >
                        <a
                            href="/profile/{authUser.username}"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Profile</a
                        >
                        <a
                            href="/my/settings?type=subscriptions"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Subscriptions</a
                        >
                        <a
                            href="/my/settings?type=payments"
                            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Payments</a
                        >
                        <Button
                            on:click="{handleLogout}"
                            class="block w-full px-4 py-2 text-left text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            >Logout</Button
                        >
                    </div>
                {/if}
            </div>
        </div>
    {/if}
</nav>

<style>
    /* Styles */
</style>
